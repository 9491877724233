import { useSelector } from 'react-redux'
import { RootState } from '../store/modules/rootReducer'

export const useUserInfo = () => {
  const { currentUser } = useSelector((store: RootState) => store.user)

  const userInfo = {
    $email: currentUser?.email,
    $name: currentUser?.name,
    $distinct_id: currentUser?.uid,
  }

  return userInfo
}
